import request from "lib/request";
import config from "config/app";
import lib from "lib/commons";
import lang from "lng/index";
const lng = lang[config.lng];

export const CURRENT_USER_REQUEST = "CURRENT_USER_REQUEST";
export const CURRENT_USER_REQUEST_ERROR = "CURRENT_USER_REQUEST_ERROR";
export const CURRENT_USER_REQUEST_SUCCESS = "CURRENT_USER_REQUEST_SUCCESS";
export const CURRENT_USER_FETCHING = "CURRENT_USER_FETCHING";

export const SPIN = "SPIN";
export const SPIN_ERROR = "SPIN_ERROR";
export const SPIN_SUCCESS = "SPIN_SUCCESS";
export const IS_SPINNING = "IS_SPINNING";

export const REGISTER = "REGISTER";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const IS_REGISTERING = "IS_REGISTERING";

export const GET_HISTORY = "GET_HISTORY";
export const GET_HISTORY_ERROR = "GET_HISTORY_ERROR";
export const GET_HISTORY_SUCCESS = "GET_HISTORY_SUCCESS";
export const IS_GETTING_HISTORY = "IS_GETTING_HISTORY";

export const GET_REWARD = "GET_REWARD";
export const GET_REWARD_ERROR = "GET_REWARD_ERROR";
export const GET_REWARD_SUCCESS = "GET_REWARD_SUCCESS";
export const IS_GETTING_REWARD = "IS_GETTING_REWARD";

export const isCurrentUserFetching = () => {
  return {
    type: CURRENT_USER_FETCHING,
  };
};

export const getCurrentUser = () => {
  return (dispatch, getState) => {
    dispatch(isCurrentUserFetching());
    request("api/user/get").then(function (response) {
      if (response.status == "successful") {
        dispatch(getCurrentUserSuccess(response));
      } else {
        if (response.error_code == "no_account") {
          lib.showDownlad();
        }
        dispatch(getCurrentUserError(response));
      }
    });
  };
};

export const getCurrentUserSuccess = (response) => {
  return {
    type: CURRENT_USER_REQUEST_SUCCESS,
    payload: response.payload,
  };
};

export const getCurrentUserError = (response) => {
  return {
    type: CURRENT_USER_REQUEST_ERROR,
    payload: response.payload,
  };
};

//Spin
export const isSpinning = (response) => {
  return {
    type: IS_SPINNING,
  };
};
export const spin = (callback = () => {}) => {
  return (dispatch, getState) => {
    dispatch(isSpinning());
    request("api/user/spin", "POST", {
      body: JSON.stringify({}),
    }).then(function (response) {
      if (response.status == "successful") {
        let reward = response.payload.reward;
        setTimeout(() => {
          dispatch(spinSuccess(response));
        }, 8000);
        callback(response.payload);
      } else {
        lib.showError(response.error_code);
        dispatch(spinError(response));
      }
    });
  };
};
export const spinSuccess = (response) => {
  return {
    type: SPIN_SUCCESS,
    payload: response.payload,
  };
};
export const spinError = (response) => {
  return {
    type: SPIN_ERROR,
    payload: response.payload,
  };
};

//Register
export const isRegistering = (response) => {
  return {
    type: IS_REGISTERING,
  };
};
export const register = (
  { name, address, phone_number, province, district },
  callback = () => {}
) => {
  return (dispatch, getState) => {
    dispatch(isSpinning());
    request("api/user/register", "POST", {
      body: JSON.stringify({
        name: name,
        address: address,
        phone_number: phone_number,
        province: province,
        district: district,
      }),
    }).then(function (response) {
      if (response.status == "successful") {
        lib.showMessage("Chúc mừng bạn đã cập nhật thông tin thành công");
        dispatch(registerSuccess(response));
        callback(response.payload);
      } else {
        lib.showError(response.error_code);
        dispatch(registerError(response));
      }
    });
  };
};
export const registerSuccess = (response) => {
  return {
    type: REGISTER_SUCCESS,
    payload: response.payload,
  };
};
export const registerError = (response) => {
  return {
    type: REGISTER_ERROR,
    payload: response.payload,
  };
};

//get history
export const isGettingHistory = () => {
  return {
    type: IS_GETTING_HISTORY,
  };
};

export const getHistory = () => {
  return (dispatch, getState) => {
    dispatch(isGettingHistory());
    request("api/user/history").then(function (response) {
      if (response.status == "successful") {
        dispatch(getHistorySuccess(response));
      } else {
        dispatch(getHistoryError(response));
      }
    });
  };
};

export const getHistorySuccess = (response) => {
  return {
    type: GET_HISTORY_SUCCESS,
    payload: response.payload,
  };
};

export const getHistoryError = (response) => {
  return {
    type: GET_HISTORY_ERROR,
    payload: response.payload,
  };
};

//get reward
export const isGettingReward = () => {
  return {
    type: IS_GETTING_REWARD,
  };
};

export const getReward = (code, callback = () => {}) => {
  return (dispatch, getState) => {
    dispatch(isGettingReward());
    request("api/user/get-reward", "POST", {
      body: JSON.stringify({
        code: code,
      }),
    }).then(function (response) {
      if (response.status == "successful") {
        lib.showMessage(`${response?.payload?.reward}`, null).then((res) => {
          dispatch(getRewardSuccess(response));
        });
      } else {
        // lib.showError(response.error_code)
        dispatch(getRewardError(response));
        callback(response.payload);
      }
    });
  };
};

export const getRewardSuccess = (response) => {
  return {
    type: GET_REWARD_SUCCESS,
    payload: response.payload,
  };
};

export const getRewardError = (response) => {
  return {
    type: GET_REWARD_ERROR,
    payload: response.payload,
  };
};
